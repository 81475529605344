<template>
  <b-modal id="bv-modal-example" v-model="modal" size="lg" hide-footer>
    <template #modal-title>
      {{ $t('betDetail.title') }} #{{ item.transactionId }}
    </template>
    <div>
      <div class="text-muted">
        <div class="table-responsive mb-0">
          <div class="w-100">
            <p class="mb-1">Fecha :</p>
            <h5 class="font-size-16">
              {{ moment(item.createdAt).format('DD-MM-YYYY HH:mm:ss') }}
            </h5>
          </div>
          <div>
            <p class="mt-4 mb-1">{{ $t('transactions.roundID') }} :</p>
            <h5 class="font-size-16">{{ item.roundId }}</h5>
          </div>
          <div v-if="item.playerId">
            <p class="mt-3 mb-1">{{ $t('betDetail.roulette') }} :</p>
            <h5 class="font-size-16">{{ item.playerId.casinoId }}</h5>
          </div>
          <div v-if="item.playerId">
            <p class="mt-3 mb-1">{{ $t('betDetail.user') }}:</p>
            <h5 class="font-size-16">{{ item.playerId.userId }}</h5>
          </div>
          <div v-if="item.playerId">
            <p class="mt-3 mb-1">{{ $t('tableReports.operator') }}:</p>
            <h5 class="font-size-16">{{ item.playerId.operator.name }}</h5>
          </div>
          <div v-if="item.playerId">
            <p class="mt-3 mb-1">{{ $t('filter.client') }}:</p>
            <h5 class="font-size-16">
              {{ item.playerId.operator.client.name }}
            </h5>
          </div>
        </div>
        <div class="mt-3">
          <div>
            <div>
              <b-table striped hover :items="item.bet" :fields="fields">
                <template #cell(number)="data">
                  {{ data.item.numbers.join('/') }}
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {
        return {
          playerId: {},
          bet: [],
        };
      },
    },
  },
  data() {
    return {
      modal: false,
      fields: [
        {
          key: 'number',
          label: this.$t('betDetail.number'),
          class: 'text-center',
        },
        {
          key: 'amount',
          label: this.$t('betDetail.amount'),
          class: 'text-center',
        },
        {
          key: 'type',
          label: this.$t('betDetail.type'),
          class: 'text-center',
        },
      ],
    };
  },
  methods: {
    closeModa() {
      this.modal = false;
    },
  },
};
</script>
